import React, { Suspense, useReducer, useEffect } from "react";
import { SignIn } from "./signin";
import { Router } from "@reach/router";
import { produce } from "immer";
import { firebase } from "../firebase";

const MainApp = React.lazy(() => import("./mainApp"));

const reducer = produce((state, action) => {
  switch (action.type) {
    case "user_login": {
      state.userStatus = "LOGGED_IN";
      return state;
    }
    case "user_logout": {
      state.userStatus = "LOGGED_OUT";
      return state;
    }
    default: {
      return state;
    }
  }
});

const defaultState = {
  userStatus: "LOGGED_OUT"
};

export const App = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      dispatch({
        type: user ? "user_login" : "user_logout"
      });
    });
  }, []);

  console.log("state", state);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        {state.userStatus === "LOGGED_OUT" && (
          <SignIn
            path="/*"
            onSuccess={user => {
              dispatch({
                type: "user_login",
                payload: user
              });
            }}
            onFail={e => {
              console.log("e", e);
            }}
          />
        )}
        {state.userStatus === "LOGGED_IN" && <MainApp path="/*" />}
      </Router>
    </Suspense>
  );
};
