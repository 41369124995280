import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = process.env.REACT_APP_TEST
  ? process.env.REACT_APP_TEST_FIREBASE_CONFIG
  : process.env.REACT_APP_FIREBASE_CONFIG;

if (firebaseConfig) {
  firebase.initializeApp(JSON.parse(firebaseConfig));
}

export { firebase };
